import {ICartItem} from "../interfaces/ICart"
import {formatter} from "./Toolbox"
import {IProject} from "../interfaces/IProject";
import {getCurrentLang} from "./Local";

export const displayItemTotal = (item: ICartItem) => {

    if (item.list === item.price) {
        return (
            <div>{formatter.format(item.quantity * (item.price / 100))}</div>
        )
    }

    return (
        <>
            <div className='text-decoration-line-through'>{formatter.format(item.quantity * (item.list / 100))}</div>
            <div className='text-danger'>{formatter.format(item.quantity * (item.price / 100))}</div>
        </>
    )
}

const displayConstructionTime = (num_weeks: number, t: Function) => {
    const label = num_weeks < 2 ? t('week') : t('weeks');
    return num_weeks + ' ' + label;
};
const displayCompletionDate = (completionDate: string) => {
    const lang = getCurrentLang()
    const dateValues = completionDate.split('-')
    const date = new Date(`${dateValues[0]}/${dateValues[1]}/${dateValues[2]}`);
    const locale = lang === 'fr' ? "fr-CA" : "en-CA";
    return date.toLocaleDateString(locale, {month: 'long', year: 'numeric'});
}

export const displayProjectInfo = (project: IProject, t: Function) => {
    return (
        <>
            <ul>
                <li>
                    {t('total_living_space')}: {project.total_living_space} {t('sq_ft')}
                </li>
                <li>
                    {t('Completion Date')}: {displayCompletionDate(project.completion_date)}
                </li>
                <li>
                    {t('Construction Time')}: {displayConstructionTime(project.construction_time, t)}
                </li>
                <li>
                    {t('Built from PakVert panels')}, {project.bottles.toLocaleString()} {t('recycled plastic bottles')}
                </li>
            </ul>
        </>
    );
}