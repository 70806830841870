import React, { useState, useRef, useEffect, ChangeEvent, KeyboardEvent } from 'react';
import { Container } from 'react-bootstrap';

interface IPinCodeInputProps {
    onComplete: (pin: string) => void;
}

const PinCodeComponent: React.FC<IPinCodeInputProps> = ({ onComplete }) => {
    const [pin, setPin] = useState<string[]>(['', '', '', '']);
    const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

    useEffect(() => {
        if (pin.every((value) => value !== '')) {
            onComplete(pin.join(''));
        }
    }, [pin, onComplete]);

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);

            if (value !== '' && index < 3) {
                inputRefs[index + 1].current?.focus();
            }
        }
    };

    const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Backspace' && index > 0 && pin[index] === '') {
            inputRefs[index - 1].current?.focus();
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Container>
                <div className='pinCodeContainer'>
                    {pin.map((digit, index) => (
                        <input
                            key={index}
                            type="tel"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleInputChange(e, index)}
                            onKeyDown={(e) => handleInputKeyDown(e, index)}
                            ref={inputRefs[index]}
                            className='pinCode'
                        />
                    ))}
                </div>
            </Container>
        </div>
    );
};

export default PinCodeComponent;
