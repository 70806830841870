import {Col, Container, Row, Image, Button} from "react-bootstrap";
import i18n from "../i18n";
import {useEffect, useState} from "react";
import apiService from "../services/api";
import Loader from "./Loader.component";
import Error from "./Error.component";
import {getCurrentLang, getUrlLangPrefix} from "../utils/Local";
import {IProject, ILastKey} from "../interfaces/IProject";
import {NavLink} from "react-router-dom";
import {displayProjectInfo} from "../utils/Helper";

const ProjectsComponent = () => {

    const {t} = i18n;
    const lang = getCurrentLang()
    const langPrefix = getUrlLangPrefix()

    const projectLink = (project: IProject) => {
        const title = project.title[lang].replaceAll('?', '').replaceAll(' ', '_');
        return `${langPrefix}/${t('_route.projects')}/${title}?id=${project.id}`
    }
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [projects, setProjects] = useState<IProject[]>([]);
    const [lastKey, setLastKey] = useState<ILastKey | undefined>(undefined);

    const fetchProjects = async () => {
        try {
            const response = await apiService.fetchProjects(3, lastKey)
            if (response.status === 200 && response.data && response.data.projects) {
                const projects = response.data.projects as IProject[];
                setProjects((prevProjects) => [...prevProjects, ...projects]);

                if (response.data.last_key) {
                    const lastEvalKey = response.data.last_key as ILastKey
                    setLastKey(lastEvalKey)
                } else {
                    setLastKey(undefined)
                }
            }

        } catch (err: any) {
            if (err.response && err.response.status && err.response.status === 404) {
                // not found
            } else {
                setError(true)
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchProjects().then(r => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (<Loader/>);
    }

    if (error) {
        return (<Error/>);
    }

    if (projects.length === 0) {
        return (<>projects not found</>);
    }

    return (
        <>
            {projects.map((project: IProject, index: number) => {
                const imgContainerOrder = index % 2 === 0 ? 'order-1 order-lg-1' : 'order-1 order-lg-2';
                const contentContainerOrder = index % 2 === 0 ? 'order-2 order-lg-2' : 'order-2 order-lg-1';
                return (
                    <Container className={`project-container mb-5`} key={index}>
                        <Row>
                            <Col xs={12} lg={6} className={`text-center mb-4  ${imgContainerOrder}`}>
                                <NavLink to={projectLink(project)} key={index}>
                                    <Image src={project.images[0].thumbnail} rounded className="mw-100"/>
                                </NavLink>
                            </Col>
                            <Col xs={12} lg={6} className={contentContainerOrder}>
                                <div className="flexContainer" style={{gap: '8px'}}>
                                    <h2>{project.title[lang]}</h2>
                                    <h3>{project.location}</h3>
                                    <p>{project.description[lang]}</p>
                                    {displayProjectInfo(project, t)}
                                    <NavLink to={projectLink(project)}>
                                        <Button className="btn-outline-primary">{t('View project')}</Button>
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                )
            })}

            <Row>
                <Col xs={12} className='text-center mt-4'>
                    {lastKey && !isLoading && (
                        <Button size="sm" variant="outline-success ps-4 pe-4 " onClick={fetchProjects}
                                className="btn-outline">{t('load_more')}</Button>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default ProjectsComponent;