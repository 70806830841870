import {Accordion, Col, Container, Row, Image, Button, Modal} from "react-bootstrap";
import {IGalleryImage} from "../interfaces/IGalleryImage";
import i18n from "../i18n";
import {useEffect, useState} from "react";
import apiService from "../services/api";
import Loader from "./Loader.component";
import Error from "./Error.component";
import {getCurrentLang} from "../utils/Local";
import {IAward, ILastKey, IAwardImage} from "../interfaces/IAward";
import Gallery from 'react-image-gallery';

const AwardsComponent = () => {

    const {t} = i18n;
    const lang = getCurrentLang();
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(false)
    const [awards, setAwards] = useState<IAward[]>([]);
    const [lastKey, setLastKey] = useState<ILastKey | undefined>(undefined);
    const [galleryImages, setGalleryImages] = useState<IGalleryImage[]>([]);

    const openGallery = (categoryIndex: number, imgIndex: number) => {
        // load images
        const images: IGalleryImage[] = []
        awards[categoryIndex].images.map((img, index) => {
            images.push({
                original: img.original,
                thumbnail: img.thumbnail,
                description: `${awards[categoryIndex].title[lang]} (${index + 1})`,
                alt: `${awards[categoryIndex].title[lang]} (${index + 1})`,
            })
            return null;
        })
        setGalleryImages(images)
        setCurrentIndex(imgIndex);
        setShowGallery(true);
    };


    const fetchAwards = async () => {
        try {
            const response = await apiService.fetchAwards(3, lastKey)
            if (response.status === 200 && response.data && response.data.awards) {
                const awards = response.data.awards as IAward[];
                setAwards((prevAwards) => [...prevAwards, ...awards]);

                if (response.data.last_key) {
                    const lastEvalKey = response.data.last_key as ILastKey
                    setLastKey(lastEvalKey)
                } else {
                    setLastKey(undefined)
                }
            }

        } catch (err: any) {
            if (err.response && err.response.status && err.response.status === 404) {
                // not found
            } else {
                setError(true)
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchAwards().then(r => {
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading) {
        return (<Loader/>);
    }

    if (error) {
        return (<Error/>);
    }

    if (awards.length === 0) {
        return (<>awards not found</>);
    }

    return (
        <>
            <Modal show={showGallery} fullscreen={true} onHide={() => setShowGallery(false)}
                   data-bs-theme="dark" className='modalGallery'>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Gallery
                        items={galleryImages}
                        startIndex={currentIndex}
                        showThumbnails={false}
                    />
                </Modal.Body>
            </Modal>

            <Accordion defaultActiveKey={['0']} alwaysOpen>
                {awards.map((award: IAward, index: number) => {
                    return (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{award.title[lang]}</Accordion.Header>
                            <Accordion.Body>
                                <Container>
                                    <Row>
                                        {award.images.map((image: IAwardImage, imgIndex: number) => {
                                            return (
                                                <Col key={imgIndex} xs={6} md={4}
                                                     className="mb-4 position-relative text-center">
                                                    <Image src={image.thumbnail} role="button"
                                                           className="mw-100"
                                                           style={{objectFit: "cover"}}
                                                           onClick={() => openGallery(index, imgIndex)}
                                                           alt={`${award.title[lang]} (${imgIndex + 1})`}
                                                           loading="lazy"/>
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Container>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>

            <Row>
                <Col xs={12} className='text-center mt-4'>
                    {lastKey && !isLoading && (
                        <Button size="sm" variant="outline-success ps-4 pe-4 " onClick={fetchAwards}
                                className="btn-outline">{t('load_more')}</Button>
                    )}
                </Col>
            </Row>
        </>
    );
};

export default AwardsComponent;