import { useState } from "react";
import { Navbar, Nav, Container, NavDropdown, Image, Button, Badge } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import i18n from "../i18n";
import useCart from "../hooks/useCart";
import { ReactComponent as FaCart } from '../assets/icons/faCart.svg';
import RecentCartItem from "./RecentCartItem.component";
import { getCurrentLang, getUrlLangPrefix } from "../utils/Local";

const NavbarComponent = () => {

    const { totalItems } = useCart();
    const [expanded, setExpanded] = useState(false);
    const { t } = i18n;
    const langPrefix = getUrlLangPrefix()
    const language = getCurrentLang()

    function getSwitchToLanguage() {
        return language === 'en' ? 'FR' : 'EN';
    }

    const { pathname } = useLocation();
    const hideLinks = pathname.startsWith('/checkout') || pathname.startsWith('/fr/checkout')

    const handleLinkClick = () => {
        setExpanded(false)
    }

    /**
     * Link for "switch language"
     * 
     * Detects the path in other language
     * 
     * TODO: cleanup this logic!
     * @returns 
     */
    function getSwitchToLink() {


        let toPath = ''

        const path = pathname.replace('/fr', '');
        const firstPath = path.split('/')[1];
        const secondPath = path.split('/')[2];


        if (language === 'fr') {

            if (firstPath === undefined || firstPath === '') {
                return '/'
            }

            toPath = _getToEngPath(firstPath)

        } else {

            if (firstPath === undefined || firstPath === '') {
                return '/fr'
            }

            toPath = '/fr/' + t('_route.' + firstPath, { lng: 'fr' })
        }


        if (secondPath === undefined) {
            return toPath + window.location.search
        }

        return toPath + '/' + secondPath + window.location.search
    }

    /** TODO: fix this routing... */
    function _getToEngPath(path: string) {

        switch (path) {
            case 'modeles':
                return 'models'
            case 'comment-ca-marche':
                return 'how-it-works'
            case 'notre-histoire':
                return 'our-story'
            case 'faq':
                return 'faq'
            case 'contactez-nous':
                return 'contact-us'
            case 'politique-de-confidentialite':
                return 'privacy-policy'
            case 'politique-de-remboursement':
                return 'refund-policy'
            case 'conditions-de-service':
                return 'terms-of-service'
            case 'la-technologie':
                return 'the-technology'
            case 'galerie':
                return 'gallery'
            case 'couverture-mediatique':
                return 'media-coverage'
            case 'commandez':
                return 'shop'
            case 'panier':
                return 'cart'
            case 'caisse':
                return 'checkout'
            case 'succes':
                return 'success'
            case 'erreur':
                return 'error'
            case 'blog':
                return 'blog'
            case 'gerer-preferences':
                return 'manage-preferences'
            case 'planifiez-un-appel':
                return 'schedule-a-call'
            case 'projets':
                return 'projects'
            default:
                return path;
        }
    }

    return (<>
        <Navbar expanded={expanded} expand="lg" sticky="top">
            <Container>

                {!hideLinks &&

                    <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="responsive-navbar-nav" />
                }
                <Navbar.Brand to={`${langPrefix}`} as={NavLink}  >
                    <Image src="/images/logo/pakville.svg" style={{ maxWidth: '150px' }} alt="PakVille"></Image>
                </Navbar.Brand>

                {!hideLinks &&
                    <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.cart')}`} as={NavLink} className="d-block d-lg-none nav-cart">
                        <FaCart />
                        <Badge bg="danger" pill >
                            {totalItems}
                        </Badge>
                    </Nav.Link>
                }


                {!hideLinks &&
                    <>

                        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">

                            <Nav className="justify-content-center" style={{ flex: '2 0' }}>

                                <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}`} as={NavLink} end>
                                    {t('home')}
                                </Nav.Link>

                                <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.the-technology')}`} as={NavLink} >
                                    {t('the_technology')}
                                </Nav.Link>

                                <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.how-it-works')}`} as={NavLink} >
                                    {t('how_it_works')}
                                </Nav.Link>

                                <NavDropdown title={t('signature_models')} id="nav-models-dropdown" className='navDropdown'>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/overview`} as={NavLink}>
                                        {t('model_overview')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/PakTokyo`} as={NavLink}>
                                        PakTokyo
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/PakAlps`} as={NavLink}>
                                        PakAlps
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/PakStockholm`} as={NavLink}>
                                        PakStockholm
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/PakShiraz`} as={NavLink}>
                                        PakShiraz
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/PakNewYork`} as={NavLink}>
                                        PakNewYork
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.models')}/PakMontreal`} as={NavLink}>
                                        PakMontréal
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.contact-us')}`} as={NavLink} >
                                    {t('contact_us')}
                                </Nav.Link>

                                <NavDropdown title={t('see_more')} id="nav-models-dropdown" className='navDropdown'>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.gallery')}`} as={NavLink}>
                                        {t('gallery')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.media-coverage')}`} as={NavLink}>
                                        {t('media_coverage')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.blog')}`} as={NavLink}>
                                        {t('blog')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.our-story')}`} as={NavLink}>
                                        {t('our_story')}
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.faq')}`} as={NavLink}>
                                        {t('faq')}
                                    </NavDropdown.Item>
                                </NavDropdown>

                            </Nav>

                            <Nav className="justify-content-center ms-auto align-items-start align-items-lg-center ">
                                <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.shop')}`} as={NavLink} className="m-0 p-0 pe-2">
                                    <Button size="sm" variant="primary" className="p-1 ps-2 pe-2" style={{ fontSize: '1rem' }}>{t('shop')}</Button>
                                </Nav.Link>

                                <Nav.Link onClick={() => handleLinkClick()} to={`${langPrefix}/${t('_route.cart')}`} as={NavLink} className="d-none d-lg-block nav-cart">
                                    <FaCart />
                                    <Badge bg="danger" pill >
                                        {totalItems}
                                    </Badge>
                                </Nav.Link>

                                <Nav.Link onClick={() => handleLinkClick()} to={getSwitchToLink()} as={NavLink} >
                                    {getSwitchToLanguage()}
                                </Nav.Link>
                            </Nav>

                        </Navbar.Collapse>



                    </>
                }

                <RecentCartItem />
            </Container >
        </Navbar>

    </>);
}

export default NavbarComponent;